import React from "react";

import { styled } from "style";

import ProductCard from "components/Card/Product";
import Section from "components/SectionWrapper";

const Wrapper = styled("div")({
  mb: 6,
});

const Heading = styled("h3")({
  maxWidth: "xl",
  px: [3, 4.5],
  mb: 4,
  mx: "auto",
});

const Grid = styled("div")({
  display: "grid",
  gridTemplateColumns: ["minmax(0px, 1fr)", null, "repeat(5, minmax(0, 1fr))"],
  columnGap: 3,
  rowGap: 4,
  maxWidth: "xl",
  mx: "auto",
  py: 5.5,
  px: 3,
});

const ProductGrid = ({ title, products }) => {
  return (
    <Wrapper>
      {title && <Heading variant="text.display1">{title}</Heading>}
      <Section as="div">
        <Grid>
          {products.map(product => (
            <ProductCard key={product._id} {...product} />
          ))}
        </Grid>
      </Section>
    </Wrapper>
  );
};

export default ProductGrid;
